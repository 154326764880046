<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Mã khai báo <span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required"
            :custom-messages="code"
          >
            <b-form-input
              v-model="dataInput.Code"
              :state="errors.length > 0 ? false:null"
              placeholder="Mã khai báo"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Ngày cấp <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="DateDeclare"
            rules="required"
            :custom-messages="DateDeclare"
          >
            <date-time-picker
              v-model="dataInput.DateDeclare"
              :disabledInput="true"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="inspector"
        >
          <label for="inspector">Người khai báo <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="UserDeclare"
          >
            <b-form-input
              v-model="dataInput.UserDeclare"
              placeholder="Người khai báo"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="company"
        >
          <label for="company">Số giấy chứng nhận kiểm định</label>
          <b-form-input
            v-model="dataInput.CertificateNumber"
            placeholder="Số giấy chứng nhận kiểm định"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Tổ chức kiểm định"
          label-for="date"
        >
          <b-form-input
            v-model="dataInput.InspectionOrganization"
            placeholder="Tổ chức kiểm định"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="File đính kèm"
          label-for="date"
        >
          <div class="d-flex">
            <b-form-input
              v-model="dataInput.fileName"
              :disabled="true"
            />

            <input
              ref="upload-file"
              type="file"
              class="d-none"
              @change="importFileExcel"
            />
            <b-button
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="uploadFile"
            >
              <feather-icon
                icon="UploadIcon"
                class="text-primary"
              />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},

    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Mã số là bắt buộc',
      },
      UserDeclare: {
        required: 'Tên người khai báo là bắt buộc',
      },
      DateDeclare: {
        required: 'Ngày cấp là bắt buộc',
      },
    }
  },
  methods: {
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

  },
}
</script>
