import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  downloadExportFile(name, urlDowload, params) {
    axiosApiInstance({
      url: urlDowload, // your url
      method: 'GET',
      responseType: 'blob', // important
      params,
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
