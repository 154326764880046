<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label for="code">Thiết bị<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="type_accident"
              rules="required"
              :custom-messages="code"
            >
              <v-select
                v-model="dataInput.equipmentId"
                :reduce="item => item.id"
                :state="errors.length > 0 ? false:null"
                label="name"
                :options="listEquipment || []"
                placeholder="Lựa chọn thiết bị"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Nơi lắp đặt</label>
            <b-form-input
              v-model="dataInput.location"
            />
          </b-form-group>
          <b-form-group>
            <label>Số lượng <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="quantity"
            >
              <b-form-input
                v-model="dataInput.quantity"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listEquipment: [],
      required,
      code: {
        required: 'Tên thiết bị là bắt buộc',
      },
      quantity: {
        required: 'Số lượng là bắt buộc',
      },

      dataInput: {
        declareEquipmentId: '',
        equipmentId: '',
        location: '',
        quantity: null,
      },

    }
  },
  created() {
    this.getListEquipment()
  },
  methods: {
    accept(bvModalEvt) {
      this.dataInput.declareEquipmentId = this.$route.params.id
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        equipmentId: '',
        location: '',
        quantity: null,

      }
    },
    async getListEquipment() {
      const res = await axiosApiInstance.get(ConstantsApi.COMBOBOX)
      this.listEquipment = res.data.data
    },

  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
