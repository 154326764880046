<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      size="lg"
      title="Xem file đính kèm"
    >
      <button-all-header
        :contentBtnAdd="'Thêm người lao động'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideDelete="false"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header"
        @search="search($event)"
      />
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{ enabled: false, selectOnCheckboxOnly: true }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- tên -->
          <span v-if="props.column.field == 'file'">
            {{ props.row.file }}
          </span>
          <!-- số giáy phếp -->
          <span v-if="props.column.field == 'number'">
            {{ props.row.number }}
          </span>
          <!-- loại -->
          <span
            v-if="props.column.field == 'type'"
            :class="colorType(props.row.type)"
          >
            {{ props.row.type }}
          </span>
          <!-- doanh nghiệp -->
          <span v-if="props.column.field == 'business'">
            {{ props.row.business }}
          </span>
          <!-- ngày bắt đầu -->
          <span v-if="props.column.field == 'dayStart'">
            {{ props.row.dayStart }}
          </span>
          <!-- ngày kết thúc -->
          <span v-if="props.column.field == 'dayEnd'">
            {{ props.row.dayEnd }}
          </span>
          <!-- statusString : Trạng thái  -->
          <span
            v-if="props.column.field == 'status' "
          >
            <b-badge
              pill
              :variant="colorStatus(props.row.status)"
              class="border-status"
            >
              <span> {{ props.row.status }}</span>

            </b-badge>

          </span>
          <!-- Chức năng -->
          <span v-if="props.column.field == 'cn'">
            <span>
              <feather-icon
                icon="DownloadIcon"
                size="18"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="rows.length || 0"
        :currentPage="currentPage"
      />
    </b-modal>
  </div>
</template>
<script>
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    idEdit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      totalItems: 20,
      currentPage: 1,
      columns: [
        {
          label: 'TÊN FILE',
          field: 'file',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          width: '200px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      rows: [
        {
          file: 'Tên file của văn bảng XYZ',
        },
        {
          file: 'Tên file của văn bảng XYZ',
        },
        {
          file: 'Tên file của văn bảng XYZ',
        },
      ],

    }
  },
  methods: {

  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }
  .action {
    margin-top: 25px;
  }
  .description {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .btn-left {
        margin-right: 0px !important;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
}
.custtom-header{
    padding-right: 20px;
}
</style>
