<template>
  <div>
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'stt'">
          {{ props.row.stt }}
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'upload'">
          <div
            class="d-flex justify-content-between item-file-download"
          >
            <input
              ref="upload-file"
              type="file"
              class="d-none"
              @change="importFile"
            />
            <b-button
              v-if="!props.row.fileName"
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="uploadFile(props.row.typeFile, false)"
            >
              <feather-icon
                icon="UploadIcon"
                class="text-primary"
              />
              Tải lên
            </b-button>

            <span
              v-b-tooltip.hover.top="'Tải tệp'"
              class="text-primary mt-1"
              @click="dowloadFile(props.row.fileName, props.row.typeFile)"
            >{{ props.row.fileName }}</span>
          </div>
        </span>
        <span v-else-if="props.column.field == 'action'">
          <b-dropdown
            v-if="props.row.fileName"
            variant="link"
            boundary="viewport"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-dark"
              />
            </template>
            <b-dropdown-item
              @click="uploadFile(props.row.typeFile, true)"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-50"
                size="18"
              />
              <span>Tải tệp</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteFile(props.row.typeFile)"
            >

              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
                size="18"
              />
              <span>Xóa</span>

            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown, BButton,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import store from '@/views/management-statistical/store'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    ShowFile,
    BButton,
    BDropdownItem,
    // EditEquip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      totalRecord: 10,
      isActive: false,
      modalIdCreate: 'modalIdCreate',
      dataList: [
        {
          stt: 1,
          name: 'Bản sao phô tô Giấy chứng nhận kết quả kiểm định của thiết bị.',
          fileName: 'Không có file',
          typeFile: 'File1',
        },
        {
          stt: 2,
          name: 'Phiếu khai báo sử dụng đối tượng kiểm định (Ban hành kèm theo mẫu tại Phụ lục Iđ, Nghị định số 44/2016/NĐ-CP ngày 15/5/2016)',
          fileName: 'Không có file',
          typeFile: 'File2',
        },
      ],
      isShow: false,
      columns: [
        {
          label: 'STT',
          field: 'stt',
          sortable: false,
          width: '40px',
        },
        {
          label: 'TÊN HỒ SƠ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'TỆP TIN ĐÍNH KÈM',
          field: 'upload',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        declareEquipmentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataInput: {
        fileName: null,
        modelFormData: {},
      },
      isNotification: false,
      typeFile: '',
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getData(this.$route.params.id)
    }
  },
  methods: {
    // Lấy danh sách fileName
    async getData(id) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_FILE_REQUIRE, {
        params: { id },
      })
      const FileName1 = data[0]
      const FileName2 = data[1]
      this.dataList[0].fileName = FileName1
      this.dataList[1].fileName = FileName2
    },

    // Tải file
    async dowloadFile(fileName, fileType) {
      const name = fileName
      const params = {
        id: this.$route.params.id,
        fileType,
      }
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_FILE_REQUIRE, params)
    },

    async deleteFile(val) {
      const params = {
        id: this.$route.params.id,
        fileType: val,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_FILE_REQUIRE, params).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getData(this.$route.params.id)
      })
        .catch(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Có lỗi xảy ra', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
    },

    uploadFile(val, isNotifi) {
      this.isNotification = isNotifi
      this.typeFile = val
      this.$refs['upload-file'].click()
    },
    async importFile(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      if (this.typeFile === 'File1') {
        this.dataList[0].fileName = this.dataInput.fileName
      } else {
        this.dataList[1].fileName = this.dataInput.fileName
      }
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
      const formData = new FormData()
      formData.append('Id', this.$route.params.id)
      formData.append('FileType', this.typeFile)
      formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
      formData.append('formFile', this.dataInput.modelFormData.files)
      formData.append('FileName', this.dataInput.fileName)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DECLARE_EQUIPMENT_UPLOAD, formData).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(this.isNotification ? 'Cập nhật tệp thành công' : 'Thêm tệp thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
        .catch(() => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Có lỗi xảy ra', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
    },

  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
