export default {
  // KHAI BÁO THIẾT BỊ
  API_DECLARE: 'DeclareEquipment/accreditations',
  CREATE_DECLARE: 'DeclareEquipment/declare-equipments',
  EDIT_DECLARE: 'DeclareEquipment/declare-equipments',
  DELETE_DECLARE: 'DeclareEquipment/delete-declare-equipments',
  DETAIL_DECLARE: 'DeclareEquipment/declare-equipments/',
  DECLARE_EQUIPMENT_UPLOAD: 'DeclareEquipment/update-file-require',
  DOWLOAD_FILE_REQUIRE: 'DeclareEquipment/download-file-require',
  LIST_FILE_REQUIRE: 'DeclareEquipment/list-file-require-name',
  DELETE_FILE_REQUIRE: 'DeclareEquipment/delete-file-require',

  // Thiết bị
  LIST_EQUIPMENT_DECLARE: 'DeclareEquipment/list-equipment-of-declare',
  CREATE_EQUIPMENT_DECLARE: 'DeclareEquipment/add-equipment-of-declare',
  DELETE_EQUIPMENT_DECLARE: 'DeclareEquipment/delete-equipment-of-declare',
  COMBOBOX: 'Equipment/get-combobox-equipment',

}
