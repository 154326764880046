<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="tabAccount">
        <info-equip
          ref="info"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='info'}"
        />
        <file-require
          class="tab-pane"
          :class="{'active':activeItem==='fileRequire'}"
        />
        <list-equip
          class="tab-pane"
          :class="{'active':activeItem==='listEquip'}"
        />
        <div class="d-flex mt-3">
          <b-button
            class="mr-1"
            variant="primary"
            @click="handleAddEdit"
          >
            Lưu lại
          </b-button>
          <b-button
            variant="outline-primary"
            type="reset"
            @click="back"
          >
            Quay lại
          </b-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoEquip from './tab-equip/Info.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/occupational-safety-health/declare-equip/constants/ConstantsApi'
import ListEquip from './tab-equip/ListEquipment.vue'
import FileRequire from './tab-equip/FileRequire.vue'

export default {
  components: {
    BButton,
    MyTabs,
    InfoEquip,
    ListEquip,
    ValidationProvider,
    ValidationObserver,
    FileRequire,
  },
  data() {
    return {
      activeItem: 'info',
      detailId: '',
      detailData: {},
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'fileRequire',
          title: 'Hồ sơ cần nộp',
          icon: 'FilePlusIcon',
          isDisabled: true,
        },
        {
          key: 'listEquip',
          title: 'Danh sách thiết bị',
          icon: 'ListIcon',
          isDisabled: true,
        },
      ],
      dataInput: {
        Code: '',
        UserDeclare: '',
        DateDeclare: '',
        CertificateNumber: '',
        InspectionOrganization: '',
        fileName: null,
        modelFormData: null,

      },
    }
  },

  watch: {
    detailData(val) {
      if (val) {
        this.dataInput = {
          Code: this.detailData.code,
          UserDeclare: this.detailData.userDeclare,
          DateDeclare: this.detailData.dateDeclare,
          CertificateNumber: this.detailData.certificateNumber,
          InspectionOrganization: this.detailData.inspectionOrganization,
          fileName: this.detailData.fileName,
        }
      }
    },
  },

  created() {
    if (this.$route.params.id) {
      this.detailId = this.$route.params.id
      this.listTabs[1].isDisabled = false
      this.listTabs[2].isDisabled = false
      this.fetchDetailData(this.detailId)
    }
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    back() {
      this.$router.push({ name: 'declare-equipment' })
      this.dataInput = {
        Code: '',
        UserDeclare: '',
        DateDeclare: '',
        CertificateNumber: '',
        InspectionOrganization: '',
        fileName: null,
        modelFormData: null,
      }
    },
    async fetchDetailData(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_DECLARE}${id}`)
      this.detailData = data
    },

    handleAddEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(success => {
        if (success) {
          if (this.$route.params.id) {
            this.handleEdit()
          } else {
            this.handleAdd()
          }
        }
      })
    },

    removeDataEmpty(value) {
      return ![undefined, null, ''].includes(value) ? value : ''
    },

    // Form input
    async handleAdd() {
      const formData = new FormData()
      if (this.dataInput.modelFormData) {
        formData.append('Code', this.dataInput.Code)
        formData.append('DateDeclare', this.dataInput.DateDeclare)
        formData.append('UserDeclare', this.dataInput.UserDeclare)
        formData.append('CertificateNumber', this.removeDataEmpty(this.dataInput.CertificateNumber))
        formData.append('InspectionOrganization', this.removeDataEmpty(this.dataInput.InspectionOrganization))
        formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
        formData.append('formFile', this.dataInput.modelFormData.files)
        formData.append('FileName', this.dataInput.fileName)
      } else {
        formData.append('Code', this.dataInput.Code)
        formData.append('DateDeclare', this.dataInput.DateDeclare)
        formData.append('UserDeclare', this.dataInput.UserDeclare)
        formData.append('CertificateNumber', this.removeDataEmpty(this.dataInput.CertificateNumber))
        formData.append('InspectionOrganization', this.removeDataEmpty(this.dataInput.InspectionOrganization))
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.CREATE_DECLARE, formData).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'declare-equipment-edit', params: { id: response?.data?.data.id } })
        this.listTabs[1].isDisabled = false
        this.listTabs[2].isDisabled = false
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    async handleEdit() {
      const formData = new FormData()
      if (this.dataInput.modelFormData) {
        formData.append('Id', this.$route.params.id)
        formData.append('Code', this.dataInput.Code)
        formData.append('DateDeclare', this.dataInput.DateDeclare)
        formData.append('UserDeclare', this.dataInput.UserDeclare)
        formData.append('CertificateNumber', this.removeDataEmpty(this.dataInput.CertificateNumber))
        formData.append('InspectionOrganization', this.removeDataEmpty(this.dataInput.InspectionOrganization))
        formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
        formData.append('formFile', this.dataInput.modelFormData.files)
        formData.append('FileName', this.dataInput.fileName)
      } else {
        formData.append('Id', this.$route.params.id)
        formData.append('Code', this.dataInput.Code)
        formData.append('DateDeclare', this.dataInput.DateDeclare)
        formData.append('UserDeclare', this.dataInput.UserDeclare)
        formData.append('CertificateNumber', this.removeDataEmpty(this.dataInput.CertificateNumber))
        formData.append('InspectionOrganization', this.removeDataEmpty(this.dataInput.InspectionOrganization))
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.put(ConstantsApi.EDIT_DECLARE, formData).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cập nhật thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'declare-equipment' })
        this.listTabs[1].isDisabled = false
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>
